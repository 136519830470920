.container {
  flex-direction: column;
  align-items: center;

  .categoryContainer {
    padding: 8px 10px;
    max-width: 99vw;
    overflow-x: auto;
    white-space: nowrap;
    gap: 10px;
    position: sticky;
    top: 0;
    z-index: 10;
    background-color: white;
    box-shadow: 0px 1px 25px gray;

    .category {
      background-color: white;
      width: max-content;
      padding: 5px 15px;
      border-radius: 8px;
      text-align: center;
      cursor: pointer;
      color: black;
      font-weight: 600;
      text-decoration: none;
    }

    .selectedCategory {
      background-color: #b5b5b595;
    }
  }
}
