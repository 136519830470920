.container {
  padding: 15px;
  flex-direction: column;
  background-color: white;
  gap: 20px;

  .header {
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid rgba(63, 63, 63, 0.5);
    padding-bottom: 20px;

    .title {
      color: black;
      font-weight: 800;
      font-size: 22px;
      line-height: 24px;
    }

    .edit {
      color: var(--tg-theme-button-color);
      font-weight: 600;
      font-size: 18px;
      cursor: pointer;
    }
  }

  .cartItems {
    flex-direction: column;
    gap: 20px;

    .item {
      justify-content: space-between;
      align-items: center;

      .info {
        gap: 10px;
        align-items: center;
        justify-content: center;

        .image {
          border-radius: 10px;
          width: 60px;
          height: 60px;
          object-fit: cover;
        }

        .nameAndOptions {
          flex-direction: column;

          .name {
            gap: 4px;
            align-items: center;

            .nameText {
              text-align: center;
              color: black;
              font-weight: 800;
              font-size: 18px;
            }

            .quantityText {
              font-weight: 800;
              font-size: 18px;
              color: #ecac41;
            }
          }

          .options {
            font-size: 14px;
          }
        }
      }

      .priseAndButtons {
        flex-direction: column;
        align-items: end;
        justify-content: space-between;

        .price {
          color: black;
          font-weight: 600;
          font-size: 16px;
        }

        .addButton {
          align-items: center;
          justify-content: center;
          height: 30px;
          width: 30px;
          border-radius: 6px;
          background-color: #ecac41;
          font-size: 14px;
          font-weight: 700;
          color: black;
          cursor: pointer;
        }

        .minusButton {
          align-items: center;
          justify-content: center;
          height: 30px;
          width: 30px;
          border-radius: 6px;
          background-color: #d5584c;
          font-size: 14px;
          font-weight: 700;
          color: black;
          cursor: pointer;
        }
      }
    }
  }

  .footer {
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid rgba(63, 63, 63, 0.5);
    padding-top: 20px;

    .title {
      color: black;
      font-weight: 800;
      font-size: 22px;
      line-height: 24px;
    }

    .allPrice {
      color: black;
      font-weight: 600;
      font-size: 16px;
    }
  }
}
