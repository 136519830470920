.drawer {
  align-items: center;
  overflow: hidden;
  border-radius: 20px 20px 0 0;
  height: 90dvh;

  .drawerBody {
    width: 100%;
    padding: 0;

    .image {
      width: 100%;
      height: 220px;
      object-fit: cover;
    }

    .addButton {
      height: 30px;
      width: 43px;
      border-radius: 6px;
      background-color: #ecac41;
      font-size: 14px;
      font-weight: 700;
      color: black;
      cursor: pointer;
    }

    .minusButton {
      display: flex;
      height: 30px;
      width: 43px;
      border-radius: 6px;
      background-color: #d5584c;
      font-size: 14px;
      font-weight: 700;
      color: black;
      cursor: pointer;
    }
  }
}
