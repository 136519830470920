.shopItem {
  width: 106px;
  max-width: 106px;
  flex-direction: column;
  align-items: center;
  gap: 4px;

  .image {
    position: relative;
    object-fit: contain;
    width: 80px;
    height: 80px;
    cursor: pointer;
  }

  .counterBadge {
    background-color: #ecac41;
    color: black;
    position: absolute;
    top: -8px;
    right: -8px;
    border-radius: 100px;
    font-size: 16px;
    line-height: 16px;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 700;
    width: 24px;
    height: 24px;
  }

  .nameBlock {
    align-items: center;
    flex-direction: column;
    font-size: 14px;
    line-height: 14px;
    gap: 4px;
    color: black;

    .name,
    .price {
      max-width: 106px;
      cursor: default;
    }
  }

  .addButton {
    justify-self: start;
    position: absolute;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30px;
    width: 90px;
    border-radius: 6px;
    background-color: #ecac41;
    font-size: 13px;
    font-weight: 700;
    color: black;
    cursor: pointer;
  }

  .minusButton {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30px;
    width: 43px;
    border-radius: 6px;
    background-color: #d5584c;
    font-size: 14px;
    font-weight: 700;
    color: black;
    cursor: pointer;
  }
}
